<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async copyListing (id, details) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/listings/' + id + '/copy'
    var copiedListing = {
      name: details.name,
      summary: details.summary,
      content: details.content,
      addressLine1: details.addressLine1,
      addressLine2: details.addressLine2,
      addressLine3: details.addressLine3,
      postCode: details.postCode,
      imageUrl: details.imageUrl,
      city: details.city,
      county: details.county,
      country: details.country
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: copiedListing,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
