<template>
  <v-container
    id="data-tables"
    tag="section"
  >
    <base-material-card
      color="indigo"
      icon="mdi-clipboard-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Manage Listings
        </div>
      </template>
      <v-data-table
        :headers="headers"
        :items="listings"
        :disable-pagination="true"
        hide-default-footer
        item-key="uniqueID"
        sort-desc
        :loading="loading"
      >
        <template v-slot:top>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="searchTerm"
                  append-icon="mdi-magnify"
                  class="ml-auto"
                  label="Search..."
                  hide-details
                  single-line
                  clearable
                  @change="filterTable"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  class="mx-auto"
                  v-model="searchCat"
                  :items="categoryOptions"
                  item-text="tagName"
                  item-value="id"
                  label="Select a category."
                  clearable
                  v-on:change="filterTable"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  :items="pageOptions"
                  v-model="rowsPerPage"
                  append-icon="mdi-chevron-down"
                  class="ml-auto"
                  label="Rows per Page"
                  hide-details
                  v-on:change="filterTable"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="text-sm-center">
                <v-btn
                  v-if="reportToggle === false"
                  color="info"
                  class="mt-4 mr-0"
                  @click="toggleReport"
                >
                  90 Day Report
                </v-btn>
                <v-btn
                  v-if="reportToggle === true"
                  color="success"
                  class="mt-4 mr-0"
                  @click="toggleReport"
                >
                  90 Day Report
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="text-sm-center">
                <v-dialog v-model="addListing" :retain-focus="false" max-width="600px" scrollable>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-4 mr-0"
                    >
                      Add Listing
                    </v-btn>
                  </template>
                  <v-card :loading="cardLoading">
                    <v-card-title>
                      <span class="headline">Add Listing</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="listingName" label="Listing Title*" :rules="listingNameRules" required></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" v-on:click.stop="addListing = false">Nevermind</v-btn>
                      <v-btn :disabled="!listingName" color="green darken-1" v-on:click.stop="createListing">Create</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
           <v-tooltip v-if="item.published === null" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click.stop="publishListingConfirm(item)"
                v-bind="attrs"
                v-on="on"
                color="success"
              >
              mdi-arrow-up-bold-circle
              </v-icon>
            </template>
            <span>Publish</span>
           </v-tooltip>
           <v-tooltip v-if="item.published !== null" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click.stop="unpublishListingConfirm(item)"
                v-bind="attrs"
                v-on="on"
                color="error"
              >
              mdi-arrow-down-bold-circle
              </v-icon>
            </template>
            <span>Unpublish</span>
           </v-tooltip>
           <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click.stop="editItem(item)"
                v-bind="attrs"
                v-on="on"
                color="success"
              >
              mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
           </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-on:click.stop="copyListingToSave(item)"
              v-bind="attrs"
              v-on="on"
              color="blue darken-1"
            >
            mdi-clipboard-multiple
            </v-icon>
            </template>
             <span>Copy Listing</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click.stop="deleteConfirm(item)"
                color="error"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
          <span>Delete</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <h2>Sorry, we couldn't find anything that matched that search.</h2>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || loading === true" color="blue darken-1" v-on:click="previousPage()"><v-icon >mdi-chevron-left</v-icon></v-btn>
        <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || loading === true" color="blue darken-1" v-on:click="nextPage()"><v-icon >mdi-chevron-right</v-icon></v-btn>
      </div>
      <v-dialog
        :retain-focus="false"
        v-model="deleteConfirmDialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text>
            This listing will be deleted, and unpublished. <br> It can be recovered later by contacting a Frog Administrator
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              v-on:click.stop="deleteConfirmDialog = false"
            >
              No, don't delete
            </v-btn>
            <v-btn
              color="red darken-1"
              v-on:click.stop="deleteItem"
            >
            Yes, delete listing
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        :retain-focus="false"
        v-model="publishConfirmDialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text>
            This listing will be published, and available immediately on public sites. <br> It can be unpublished or deleted at any point.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              v-on:click.stop="publishConfirmDialog = false"
            >
              No, don't publish
            </v-btn>
            <v-btn
              color="green darken-1"
              v-on:click.stop="publishListing"
            >
            Yes, publish listing
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        :retain-focus="false"
        v-model="unpublishConfirmDialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text>
            This listing will be unpublished, and immediately removed from public sites. <br> It can be re - published or deleted at any point.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              v-on:click.stop="unpublishConfirmDialog = false"
            >
              No, don't unpublish
            </v-btn>
            <v-btn
              color="red darken-1"
              v-on:click.stop="unpublishListing"
            >
            Yes, unpublish listing
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog :retain-focus="false" v-model="copyDialog" max-width="800">
        <v-card>
          <v-card-title>
            <span class="headline">Copy Listing - {{copyListing.name}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-col cols="12">
                <v-text-field v-model="copyListing.name" label="Listing Title*" :rules="listingNameRules" required></v-text-field>
              </v-col>
              <v-row>
                <v-col cols="12">
                  <v-textarea outlined max-length="100" :counter="100" v-model="copyListing.summary" label="Summary"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined max-length="1200" :counter="1200" v-model="copyListing.content" label="Content"></v-textarea>
                </v-col>
              </v-row>
                <v-row>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field v-model="copyListing.addressLine1" label="Address Line 1"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field v-model="copyListing.addressLine2" label="Address Line 2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field v-model="copyListing.addressLine3" label="Address Line 3"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field v-model="copyListing.postCode" validate-on-blur label="Post Code" :rules="postcodeRules"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field v-model="copyListing.city" label="City"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field v-model="copyListing.county" label="County"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3" sm="6">
                    <v-text-field v-model="copyListing.country" label="Country"></v-text-field>
                  </v-col>
                </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" v-on:click.stop="copyDialog = false">Cancel</v-btn>
            <v-btn color="green darken-1" v-on:click.stop="copySaveListing()">Copy</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
        <v-progress-circular
          v-if="snackColor === 'info'"
          color="white"
          indeterminate
        ></v-progress-circular>
        {{ snackText }}
      </v-snackbar>
    </base-material-card>
  </v-container>
</template>

<script>
import listingManagement from '@/Services/Listings/ListingManagement'
import createListing from '@/Services/Listings/CreateListing'
import deleteListing from '@/Services/Listings/DeleteListing'
import getListing from '@/Services/Listings/ListingDetails'
import CopyListingAPI from '@/Services/Listings/CopyListing'
import publishListing from '@/Services/Listings/PublishListing'
export default {
  data: () => ({
    reportToggle: false,
    publishListingID: null,
    unpublishListingID: null,
    publishConfirmDialog: false,
    unpublishConfirmDialog: false,
    snack: false,
    snackColor: '',
    snackText: '',
    snackTimeout: null,
    searchURL: null,
    nextCursor: null,
    prevCursor: null,
    rowsPerPage: 10,
    pageOptions: [5, 10, 20, 50, 100],
    categoryOptions: [
      {
        text: 'Please Select',
        value: null
      }
    ],
    copyDialog: false,
    confirmDialog: false,
    deleteConfirmDialog: false,
    loading: true,
    cardLoading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'uniqueID'
      },
      { text: 'Name', value: 'name' },
      { text: 'Postcode', value: 'postcode' },
      { text: 'Category', value: 'category' },
      { text: 'Published Date', value: 'publishedDate' },
      { text: 'Last Edited', value: 'lastEditedDate' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    listings: [],
    listingName: '',
    listingNameRules: [
      v => !!v || 'Name is required'
    ],
    summaryRules: [
      v => (v && v.length <= 100) || 'Summary must be less than 100 characters'
    ],
    contentRules: [
      v => (v && v.length <= 1200) || 'Content must be less than 1200 characters'
    ],
    postcodeRules: [
      v => !!v || 'Postcode Required',
      v => /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/.test(v) || 'Postcode must be valid'
    ],
    errors: [],
    searchTerm: null,
    searchCat: null,
    addListing: false,
    copyListing: {
      ID: null,
      name: null,
      summary: null,
      content: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      postCode: null,
      imageUrl: null,
      city: null,
      county: null,
      country: null
    },
    defaultCopyListing: {
      ID: null,
      name: null,
      summary: null,
      content: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      postCode: null,
      imageUrl: null,
      city: null,
      county: null,
      country: null
    }
  }),
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.loading = true
      await listingManagement.init(this.rowsPerPage, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'listings', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed.'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
      await listingManagement.getCat()
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'categoryOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load categories.'
            this.snackTimeout = 3000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      this.searchTerm = null
      this.searchCat = null
    },
    async previousPage () {
      this.loading = true
      await listingManagement.getPreviousPage(this.rowsPerPage, this.searchURL, this.prevCursor, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'listings', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get previous results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async nextPage () {
      this.loading = true
      await listingManagement.getNextPage(this.rowsPerPage, this.searchURL, this.nextCursor, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'listings', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get next results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async createListing () {
      this.cardLoading = true
      await createListing.createListing(this.listingName)
        .then((response) => {
          if (response.status === 200) {
            this.cardLoading = false
            this.addListing = false
            this.initialize()
            const editURL = '/manage/edit-listing/' + response.data.uniqueID
            this.$router.push(editURL)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to create listing'
            this.snackTimeout = 3000
          }
        })
    },
    async copyListingToSave (item) {
      await getListing.getDetails(item.uniqueID)
        .then((response) => {
          this.copyListing.ID = item.uniqueID
          this.copyListing.name = response.data.name
          this.copyListing.summary = response.data.summary
          this.copyListing.content = response.data.content
          this.copyListing.addressLine1 = response.data.addressLine1
          this.copyListing.addressLine2 = response.data.addressLine2
          this.copyListing.addressLine3 = response.data.addressLine3
          this.copyListing.postCode = response.data.postCode
          this.copyListing.imageUrl = response.data.imageUrl
          this.copyListing.city = response.data.city
          this.copyListing.county = response.data.county
          this.copyListing.country = response.data.country
          this.copyDialog = true
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    async copySaveListing () {
      this.copyDialog = false
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Copying...'
      this.snackTimeout = -1
      await CopyListingAPI.copyListing(this.copyListing.ID, this.copyListing)
        .then((response) => {
          if (response.status === 200) {
            this.snackColor = 'success'
            this.snackText = 'Listing copied successfully!'
            this.snackTimeout = 3000
            this.initialize()
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to copy listing'
            this.snackTimeout = 3000
          }
        })
    },
    editItem (item) {
      const url = '/manage/edit-listing/' + item.uniqueID
      this.$router.push(url)
    },
    deleteConfirm (item) {
      this.deleteListingID = item.uniqueID
      this.deleteConfirmDialog = true
    },
    async deleteItem () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Deleting...'
      this.snackTimeout = -1
      await deleteListing.deleteListing(this.deleteListingID)
        .then((response) => {
          if (response.status === 200) {
            this.deleteConfirmDialog = false
            this.initialize()
            this.snackColor = 'success'
            this.snackText = 'Listing was deleted successfully!'
            this.snackTimeout = 3000
          } else {
            this.deleteConfirmDialog = false
            this.snackColor = 'error'
            this.snackText = 'Failed to delete listing.'
            this.snackTimeout = 3000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    closeReset () {
      this.alertDialog = false
      this.initialize()
    },
    async filterTable () {
      this.loading = true
      const term = this.searchTerm
      const cat = this.searchCat
      let url
      if (this.reportToggle) {
        url = 'articles/listings/admin?count=' + this.rowsPerPage + '&report=' + this.reportToggle
      } else {
        url = 'articles/listings/admin?count=' + this.rowsPerPage
      }
      let extraParam = null
      if (term && cat) {
        extraParam = '&term=' + term + '&cat=' + cat
      } else if (term && !cat) {
        extraParam = '&term=' + term
      } else if (!term && cat) {
        extraParam = '&cat=' + cat
      }
      if (extraParam !== null) {
        url = url + extraParam
      }
      if (this.reportToggle) {
        if (extraParam !== null) {
          this.searchURL = '?count=' + this.rowsPerPage + extraParam + '&report=' + this.reportToggle
        } else if (extraParam === null) {
          this.searchURL = '?count=' + this.rowsPerPage + '&report=' + this.reportToggle
        }
      } else if (!this.reportToggle) {
        if (extraParam !== null) {
          this.searchURL = '?count=' + this.rowsPerPage + extraParam
        } else if (extraParam === null) {
          this.searchURL = '?count=' + this.rowsPerPage
        }
      }
      await listingManagement.filterTable(url)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'listings', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to retrieve results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.$set(this, 'listings', [])
          this.loading = false
        })
    },
    publishListingConfirm (listing) {
      this.publishListingID = listing.uniqueID
      this.publishConfirmDialog = true
    },
    async publishListing () {
      const publishID = this.publishListingID
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Publishing...'
      this.snackTimeout = -1
      await publishListing.publishListing(publishID)
        .then((response) => {
          this.publishConfirmDialog = false
          this.initialize()
          this.snackColor = 'success'
          this.snackText = 'Listing published successfully!'
          this.snackTimeout = 3000
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    unpublishListingConfirm (listing) {
      this.unpublishListingID = listing.uniqueID
      this.unpublishConfirmDialog = true
    },
    async unpublishListing () {
      const unpublishID = this.unpublishListingID
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Publishing...'
      this.snackTimeout = -1
      await publishListing.unpublishListing(unpublishID)
        .then((response) => {
          this.unpublishConfirmDialog = false
          this.initialize()
          this.snackColor = 'error'
          this.snackText = 'Listing unpublished successfully!'
          this.snackTimeout = 3000
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    toggleReport () {
      this.reportToggle = !this.reportToggle
      this.initialize()
    }
  }
}
</script>
